<script>
  
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: "LogoutView",
    components: {
    },
    data() {
      return {
        //
      };
    },
    methods: {
    },
    created() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
  });
  </script>
<template>
  <div>
    <h1>logging out</h1>
  </div>
</template>
