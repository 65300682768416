<script>
  
  import { defineComponent } from 'vue';
  import { put } from '../common/api';


  export default defineComponent({
    name: "LoginView",
    components: {
    },
    data() {
      
      let today = new Date();

      return {
        today: today,
      };
    },
    methods: {
        login(password) {
            this.loading = true;

            put('login', { password: password })
                .then(result => {
                    this.loading = false;
                    if (!result.ok) {
                        alert("Login failed");
                    }
                    else {
                        return result.json();
                    }
                })
                .then(data => {
                    if (data.token) {
                        localStorage.setItem('token', data.token);
                        this.$router.push('/today')
                    }
                });

      },
    },
    created() {
      if (localStorage.getItem('token')) {
        this.$router.push('/today');
      }
      else {
        let password = prompt("Enter your password");
        this.login(password);
      }
    },
  });
  </script>
<template>
  <div>
    <h1>Login</h1>

    <p>Todays date is: {{today}}</p>
  </div>
</template>
