<template>
  <div>
    <router-view/>
    <app-footer/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import AppFooter from './AppFooter.vue'

export default defineComponent({
  name: 'App',
  components: {
    AppFooter
  },
  data() {
    return {
      //
    }
  }
})
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
