
<template>
  <div style="margin-top:10px; display: flex; flex-direction: row; align-items: center; justify-content: center; height: 100%">
    <MDBPagination>
        <!--<MDBPageNav prev @click="previous()"></MDBPageNav>-->
        <MDBPageItem 
            v-for="month in nav_months" 
            :key="month.number" 
            :disabled="isInFuture(month)" 
            :style="isInFuture(month) ? {} : {'cursor': 'pointer'}"
            :active="month.number == selected_month" 
            @click="!!isInFuture(month) || goTo(month.number, month.year)"
        >
                {{month.name}}
            
        </MDBPageItem>
        <!--<MDBPageNav 
            next
            @click="!!isInFuture(nav_months[2]) || next()" 
            :disabled="isInFuture(nav_months[2])"
        >
        </MDBPageNav>-->
    </MDBPagination>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import {
    MDBPagination,
    //MDBPageNav,
    MDBPageItem
  } from 'mdb-vue-ui-kit';

export default defineComponent({
    name: "MonthNav",
    components: {
        MDBPagination,
        //MDBPageNav,
        MDBPageItem,
    },
    props: {
        selected_month: Number,
        selected_year: Number,
    },
    data() {
        return {
            nav_months: [],
            monthNames: [
                "January", 
                "February", 
                "March", 
                "April", 
                "May", 
                "June",
                "July", 
                "August", 
                "September", 
                "October", 
                "November", 
                "December"
            ],
        }
    },
    methods: {
        next() {
            this.$router.push('/monthview/' + this.selected_year + '/' + (this.selected_month + 1) + '/')
        },
        previous() {
            this.$router.push('/monthview/' + this.selected_year + '/' + this.selected_month + '/')
        },
        goTo(number, year) {
            this.$router.push('/monthview/' + year + '/' + (number + 1) + '/')
        },
        isInFuture(month) {
            let today = new Date()
            return month.year > today.getFullYear() || (month.number > today.getMonth() && month.year >= today.getFullYear())
        },
        getMonthNavMonths(month, year) {

            let today = new Date()
            today.setMonth(month)
            today.setFullYear(year)
            let months = [];
            for (let i = -2; i < 3; i++) {
                let date = new Date(today.getTime());
                date.setMonth(month + i);
                months.push({"name": this.monthNames[date.getMonth()], "number": date.getMonth(), "year": date.getFullYear()})
            }
            this.nav_months = months;
        },
    },
    created() {
        this.getMonthNavMonths(this.selected_month, this.selected_year);
    },
    watch: {
        selected_month: function() {
            this.getMonthNavMonths(this.selected_month, this.selected_year);
        },
    }
})
</script>

<style>

</style>
