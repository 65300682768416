import { createRouter, createWebHistory } from 'vue-router'
import MonthView from '../views/MonthView.vue'
import HomeView from '../views/HomeView.vue'
import LinkView from '../views/LinkView.vue'
import LogoutView from '../views/LogoutView.vue'
import LinkOauthReturnView from '../views/LinkOauthReturnView.vue'
import LoginView from '@/views/LoginView.vue'


const this_year = new Date().getFullYear();
const this_month = new Date().getMonth() + 1;


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutView,
    },
    {
      path: '/monthview/:year/:month*',
      name: 'month',
      component: MonthView,
      props(route) {
        const props = { ...route.params }
        props.month = +props.month
        props.year = +props.year
        return props
      },
    }, 
    {
      path: "/today*",
      name: 'catchAll',
      redirect: '/monthview/' + this_year + '/' + this_month + '/'
    },
    {
      path: "/link",
      name: "link",
      component: LinkView
    },
    { 
      path: "/link-oauth-return",
      name: "link-oauth-return",
      component: LinkOauthReturnView 
    },
    {
      path: '/home',
      name: 'home',
      component: HomeView
    },
    {
      path: "/",
      name: 'catchAll',
      redirect: '/monthview/' + this_year + '/' + this_month + '/'
    },
    {
      path: "/:catchAll(.*)",
      name: 'catchAll',
      redirect: '/monthview/' + this_year + '/' + this_month + '/'
    }
  ]
})

export default router
