<script>
 import {
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
    } from 'mdb-vue-ui-kit';
  import 'vue-json-viewer/style.css'
  import JsonViewer from 'vue-json-viewer/ssr';
  import { reactive, defineComponent } from 'vue';
  import MonthNav from '../components/MonthNav.vue';
  import BudgetItemGrid from '../components/BudgetItemGrid.vue';  
  import CategoryGrid from '@/components/CategoryGrid.vue';
  import { checkLogin } from '../common/auth';
  import { get } from '../common/api';

  export default defineComponent({
    name: "MonthView",
    components: {
      BudgetItemGrid,
      CategoryGrid,
      MonthNav,
      MDBModal,
      MDBModalBody,
      MDBModalHeader,
      MDBModalTitle,
      JsonViewer,
    },
    props: {
      month: Number,
      year: Number
    },
    data() {

      return {
        expenses: reactive([]),
        savings: reactive([]),
        incomes: reactive([]),
        loading: true,
        selected_month: this.month - 1,
        selected_year: this.year,
        expense_categories: [],
        show_modal: false,
        categories: reactive([]),
        selected_category: reactive({}),
      };
    },
    methods: {
      setSelectedTransaction(transaction) {
        console.log("transaction recieved")
        this.selected_transaction = transaction;
      },
      setSelectedCategory(category) {
        console.log("category recieved")
        console.log(category)
        this.selected_category = category;
      },
      showDetailView() {
        console.log("show modal")
        this.show_modal = true;
      },
      closeModal() {
        this.show_modal = false;
      },
      transactionEdited(transaction) {
        console.log("transaction edited")
        this.updateTransaction(transaction);
        this.calculateCategoryTotals();
      
      },
      updateTransaction (transaction) {
        if ("v3_type" in transaction) {
          if (transaction['v3_type'] == 'investment') {
            const index = this.savings.findIndex(saving => saving.date_transaction_id === transaction.date_transaction_id);
            if (index !== -1) {
              this.savings.splice(index, 1, transaction);
            }
            else {
              this.savings.push(transaction);
            }
          }
          else if (transaction['v3_type'].includes('expense')) {
            const index = this.expenses.findIndex(expense => expense.date_transaction_id === transaction.date_transaction_id);
            if (index !== -1) {
              this.expenses.splice(index, 1, transaction);
            }
            else {
              this.expenses.push(transaction);
            }
          }
          else if (transaction['v3_type'].includes('income')) {
            const index = this.incomes.findIndex(income => income.date_transaction_id === transaction.date_transaction_id);
            if (index !== -1) {
              this.incomes.splice(index, 1, transaction);
            }
            else {
              this.incomes.push(transaction);
            }
          }
          else {
            console.log(transaction)
          } 
        }
        else {
          console.log(transaction)
        }
      },
      calculateCategoryTotals() {
        console.log("calculating category totals")
        let category_totals = {};
        this.expenses.forEach((expense) => {
          if (expense.v3_category in category_totals) {
            category_totals[expense.v3_category] += expense.v3_amount
          }
          else {
            category_totals[expense.v3_category] = expense.v3_amount
          }
        });
        let newCategories = [];
        this.categories.forEach((category) => {
          if (category.name in category_totals) {
            category.spent = category_totals[category.name];
          }
          else {
            category.spent = 0;
          }
          newCategories.push(category);
        });
        this.categories = newCategories;
      },
      getCategories() {
        this.loading = true;

        get('categories')
          .then(result => result.json())
          .then(data => {
            this.categories = data
          });
          
      },
      getTransactions() {
        this.loading = true;
        let mm = String(this.selected_month + 1).padStart(2, "0")
        let yyyy = String(this.selected_year)
        var query = '?month=' + (mm) + '&year=' + (yyyy);
        get('transactions' + query)
          .then(result => result.json())
          .then(result => result.Items)
          .then(data => data = this.sortTransactions(data))
          .then(remoteRowData => {
            this.expenses = []
            this.incomes = []
            this.savings = []

            remoteRowData.forEach((transaction) => {
              if ("v3_type" in transaction) {
                if (transaction['v3_type'] == 'investment') {
                  this.savings.push(transaction);
                }
                else if (transaction['v3_type'].includes('expense')) {
                  this.expenses.push(transaction);
                }
                else if (transaction['v3_type'].includes('income')) {
                  this.incomes.push(transaction)
                }
                else {
                  console.log(transaction)
                } 
              }
              else {
                console.log(transaction)
              }
            });

            this.loading = false;
          })
          .catch(err => { alert(err); this.loading = false; });
          
      },
      sortTransactions (rowData) {
        if (rowData) {
          rowData.sort(function(a, b) {
            let a_date = Date.parse(a.authorized_date ? a.authorized_date : a.date);
            let b_date = Date.parse(b.authorized_date ? b.authorized_date : b.date);
            if (a_date < b_date) {
              return -1;
            }
            else if (a_date > b_date) {
              return 1
            }
            return 0;
          });
        }
        return rowData
      },
      
    },
    created() {
      checkLogin();
      this.getCategories();
      //this.getExpenseCategories();
      this.getTransactions();
      window.addEventListener('keydown', (e) => {

          if (e.key == 'Escape') {
            if (this.selected_transaction) {
              this.showDetailView();
            }
            e.stopPropagation();
          }

        });
    },
    watch: {
      expenses() {
        this.calculateCategoryTotals();
      },
      month(newmonth) {
        this.selected_month = newmonth - 1;
        this.getTransactions();
      },
      year(newyear) {
        this.selected_year = newyear;
        this.getTransactions();
      },
    }
  });
  </script>

<template>
  <div>
    <MDBModal
      id="exampleModal"
      tabindex="-1"
      labelledby="detailViewModal"
      v-model="show_modal"
      size="lg"
    >
      <MDBModalHeader>
        <MDBModalTitle id="detailViewModalLabel">{{selected_transaction.name}}</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <JsonViewer
          :value=selected_transaction
          :expand-depth=2
          copyable
          expanded
        >
        </JsonViewer>
      </MDBModalBody>
    </MDBModal>
    <MonthNav
      :selected_month="selected_month" 
      :selected_year="selected_year" 
    />
    <CategoryGrid
      :data="categories"
      :loading="loading"
      @category-selected="setSelectedCategory"
      />
    <BudgetItemGrid
      heading="spending"
      :data="expenses"
      :loading="loading"
      :expense_categories="categories"
      :selected_category="selected_category"
      @transaction-selected="setSelectedTransaction"
      @transaction-edited="transactionEdited"
      @transaction-deleted="transactionEdited"
    />
    <BudgetItemGrid
      heading="income"
      :data="incomes"
      :loading="loading"
      :selected_category="selected_category"
      @transaction-selected="setSelectedTransaction"
      @transaction-deleted="transactionEdited"
      @transaction-edited="transactionEdited"

    />
    <BudgetItemGrid
      heading="saving"
      :data="savings"
      :loading="loading"
      :selected_category="selected_category"
      @transaction-selected="setSelectedTransaction"
      @transaction-deleted="transactionEdited"
      @transaction-edited="transactionEdited"
    />
  </div>
</template>
