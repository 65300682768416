<template>
    <div class="budget-item-grid">
        <h4 class="budget-item-grid-title">{{heading}}</h4>
        <div style="display: flex; flex-direction: column; height: 100%">
            <div style="width: 100%; flex: 1 1 auto;">
                <ag-grid-vue
                    :style="{ height }"
                    class="ag-theme-alpine"
                    :columnDefs="columnDefs"
                    :rowData="griddata"
                    rowSelection="multiple"
                    @gridReady="onGridReady"
                    @cellValueChanged="handleCellValueChanged"
                    :overlayLoadingTemplate="overlayLoadingTemplate"
                    @selection-changed="onSelectionChanged"
                >
                </ag-grid-vue> 
            </div>
            </div>
        </div>
</template>

<script>
    import { API_ROOT } from '../config';
    import { API_TOKEN } from '../config';
    import "ag-grid-community/styles/ag-grid.css";
    import "ag-grid-community/styles/ag-theme-alpine.css";
    import { AgGridVue } from "ag-grid-vue3";
    import { ref } from "vue";

let rowHeight = 42;

let getName = params => {
    return params.data.v3_name ? params.data.v3_name : params.data.name
}

let formatAmount = params => {
    return '$' + Number(params.data.v3_amount).toFixed(2)
}

let formatDate = params => {
    return params.data.v3_date ? params.data.v3_date.slice(-2, params.data.v3_date.length) : '';
}

export default {
    
    name: "BudgetItemGrid",
    props: {
        expense_categories: Array,
        data: Array,
        loading: Boolean,
        heading: String,
        selected_category: Object,
    },
    components: {
      AgGridVue,
    },
    emits: ['transaction-selected', 'transaction-edited', 'transaction-deleted'],
    data() {
        return {
            griddata: [],
            gridApi: ref(null),
            columnApi: ref(null),
            height: "200px",    
            overlayLoadingTemplate: null,
            shiftDown: null,
            deleteDown: null,
            columnDefs: this.getColumnDefs(),
        }
    },
    methods: {
        getColumnDefs() {
            return [
                { 
                    colId: 'v3_date',
                    headerName: 'Day',
                    valueFormatter: formatDate, 
                    sortingOrder: ["desc", "asc"], 
                    sortable: true, 
                    filter: true,
                    flex: 1,
                    editable: true,
                },
                { 
                    field: 'v3_name',
                    headerName: "Name",
                    valueGetter: getName,
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 3, 
                    editable: true,
                },
                { 
                    colId: 'v3_amount',
                    headerName: 'Amount',
                    field: 'v3_amount',
                    valueFormatter: formatAmount, 
                    sortable: true, 
                    cellDataType: 'number',
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                },
                { 
                    colId: 'v3_category',
                    field: 'v3_category', 
                    headerName: 'Category',
                    sortable: true, 
                    filter: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: { 
                        values: this.expense_categories?.map(x => x.name),
                    },
                    valueFormatter: (params) => this.expense_categories?.find(x => x.name === params.value)?.display_name,
                    valueGetter: (params) => params.data.v3_category,
                    flex: 2, 
                    editable: true, 
                },
                { 
                    colId: 'v3_account_name',
                    headerName: 'Account',
                    field: 'v3_account_name',
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: true,
                }
            ]
        },
        onSelectionChanged() {
            const selectedRows = this.gridApi.getSelectedRows();
            var selected_transaction =
                selectedRows.length === 1 ? selectedRows[0] : null;
            console.log("emitting selected transaction")
            this.$emit('transaction-selected', selected_transaction);
        },
        handleCellValueChanged(params) {
            console.log(params.oldValue)
            console.log(params.newValue)
            params.node.data.v3_amount = Number(params.node.data.v3_amount);

            const request_data = {
                method: "PATCH",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': API_TOKEN
                }),
                mode: 'cors',
                body: JSON.stringify(params.node.data),
            };
            console.log("emitting transaction-edited");
            console.log(params.node.data)
            this.$emit('transaction-edited', params.node.data);

            fetch(API_ROOT + '/transactions/', request_data)
                .then(result => result.json())
                .then(data => {
                    this.griddata = this.griddata.map(x => x['transaction_id'] == data['transaction_id'] ? data : x);
                    return data;
                })
                .then(data => {
                    console.log("emitting transaction-edited");
                    console.log(data)
                    this.$emit('transaction-edited', data);
                })
                .catch(err => { alert("Failed to update, check console"); console.log(err); });
        },
        deleteTransaction(transaction) {
            const request_data = {
                method: "PATCH",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': API_TOKEN
                }),
                mode: 'cors',
                body: JSON.stringify(
                    {
                        date_transaction_id: transaction['date_transaction_id'],
                        v3_deleted: true
                    }
                )
            };
            
            fetch(API_ROOT + '/transactions/', request_data)
                .then(() => {
                    let index = this.griddata.findIndex(x => x['transaction_id'] == transaction['transaction_id'])
                    console.log(index)
                    if (index >= 0){
                        this.griddata.splice(index, 1)
                        this.calculateHeightAndTotal(); 
                    }
                    this.selected_transaction = null
                })
                .then(data => {
                    console.log("emitting transaction-deleted")
                    this.$emit('transaction-deleted', data);
                })
                .catch(err => { alert("Failed to delete, check console"); console.log(err) });
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            //this.gridApi.sizeColumnsToFit();
        },
        generatePinnedBottomData() {
            // generate a row-data with null values
            let result = {};
            this.gridColumnApi.getAllGridColumns().forEach(item => {
                result[item.colId] = null;
            });
            return this.calculatePinnedBottomData(result);
        },
        calculatePinnedBottomData(target) {
            //**list of columns fo aggregation**
            let columnsWithAggregation = ['v3_amount']
            columnsWithAggregation.forEach(element => {
                target[element] = 0;
                this.gridApi.forEachNodeAfterFilter((rowNode) => {
                    if (rowNode.data[element]) 
                        target[element] += Number(Number(rowNode.data[element]).toFixed(2));
                });
                // if (target[element]) {
                //     target[element] = `${target[element].toFixed(2)}`;
                // }
                // else {
                //     target[element] = 0;
                // }
            })
            return target;
        },
        calculateHeightAndTotal() {
            this.height = (rowHeight * (this.griddata.length + 2) + 10).toString() + "px";
            //let pinnedBottomData = this.generatePinnedBottomData();
            //this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
        }
    },
    watch: {
        data: function (newData) {
            this.griddata = newData;
            this.calculateHeightAndTotal()
        },
        expense_categories: function () {
            this.columnDefs = this.getColumnDefs();
        },
        loading: function (newValue) {
            if (newValue) {
                this.gridApi && this.gridApi.showLoadingOverlay();
            }
            else {
                this.gridApi && this.gridApi.hideOverlay();
            }
        },
    },
    updated() {
        if (this.loading) {
            this.gridApi && this.gridApi.showLoadingOverlay();
        }
        else {
            this.gridApi && this.gridApi && this.gridApi.hideOverlay();
        }
        this.calculateHeightAndTotal()
        if (this.selected_category) {
            console.log("selecting category: " + this.selected_category.name)
            this.gridApi.deselectAll();
            this.gridApi.forEachNode(node => node.data.v3_category == this.selected_category.name ? node.setSelected(true) : null);
        } else {
            this.gridApi.deselectAll();
        }
    },
    created() {
        this.overlayLoadingTemplate =
            '<span class="ag-overlay-loading-center">Getting transactions...</span>';
        
        window.addEventListener('keydown', (e) => {

            if (e.key == 'Delete') {
                this.deleteDown = true;
            }
            else if (e.key == 'Shift') {
                this.shiftDown = true;
            }

            if (this.deleteDown && this.shiftDown && this.selected_transaction) {
                e.preventDefault();
                e.stopPropagation();
                this.deleteTransaction(this.selected_transaction)
            }

        });

        window.addEventListener('keyup', (e) => {

            if (e.key == 'Delete') {
                this.deleteDown = false;
            }
            else if (e.key == 'Shift') {
                this.shiftDown = false;
            }
            
        });
    }

}
</script>

<style scoped>
    .budget-item-grid {
        margin-bottom: 40px;
        margin-top: 10px;   
    }
    .budget-item-grid-title {
        margin-left: 50px;
    }
</style>