<template>
    <div class="budget-item-grid">
        <h4 class="budget-item-grid-title">spending categories</h4>
        <div style="display: flex; flex-direction: column; height: 100%">
            <div style="width: 100%; flex: 1 1 auto;">
                <ag-grid-vue
                    class="ag-theme-alpine"
                    :columnDefs="columnDefs"
                    :rowData="griddata"
                    :style="{ height: height }"
                    :rowSelection="{ mode: 'multiRow', checkboxes: false, headerCheckbox: false, enableClickSelection: true, enableSelectionWithoutKeys: true }"
                    :enableClickSelection="true"
                    :enableSelectionWithoutKeys="true"
                    @gridReady="onGridReady"
                    :overlayLoadingTemplate="overlayLoadingTemplate"
                    @selection-changed="onSelectionChanged"
                >
                </ag-grid-vue> 
            </div>
            </div>
        </div>
</template>


<script>
let rowHeight = 42;
import { AgGridVue } from "ag-grid-vue3";
import { ref } from "vue"

export default {
    name: 'CategoryGrid',
    props: {
        data: Array,
        loading: Boolean,
    },
    components: {
      AgGridVue,
    },
    emits: ['category-selected'],
    data() {
        return {
            
            griddata: [],
            gridApi: ref(null),
            columnApi: ref(null),
            height: "200px",    
            overlayLoadingTemplate: null,
            shiftDown: null,
            deleteDown: null,            
            columnDefs: [
                { 
                    colId: 'id',
                    headerName: '',
                    field: 'id',
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 1, 
                    editable: false,
                },
                { 
                    colId: 'category',
                    headerName: 'Category',
                    field: 'display_name',
                    sortingOrder: ["desc", "asc"], 
                    sortable: true, 
                    filter: true,
                    flex: 3,
                    editable: false,
                },
                { 
                    colId: 'spent',
                    field: 'spent',
                    headerName: "Spent",
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: false,
                    valueGetter: 'data.spent | 0',
                    valueFormatter: '"$" + Number(value).toFixed(2)',
                },
                { 
                    colId: 'budgeted',
                    headerName: 'Budgeted',
                    field: 'budgeted',
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: false,
                    valueGetter: 'data.budgeted | 0',
                    valueFormatter: '"$" + Number(value).toFixed(2)',
                },
                { 
                    colId: 'difference',
                    field: 'difference', 
                    headerName: '',
                    sortable: true, 
                    filter: true,
                    valueGetter: 'data.budgeted - data.spent',
                    valueFormatter: '"$" + Number(value).toFixed(2)',
                    flex: 2, 
                    editable: false, 
                    cellStyle: params => {
                        if (params.value > 0) {
                            return { color: 'green'};
                        }
                        else if (params.value < 0) {
                            return { color: 'red'};
                        }

                        return null;
                    }
                },
                
            ],
        }
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            //this.gridApi.sizeColumnsToFit();
        },
        onSelectionChanged() {
            console.log("selection changed")
            const selectedRows = this.gridApi.getSelectedRows();
            var selected_category =
                selectedRows.length === 1 ? selectedRows[0] : null;
            console.log("emitting selected category")
            this.$emit('category-selected', selected_category);
        },
        generatePinnedBottomData() {
            // generate a row-data with null values
            let result = {};
            // this.gridColumnApi.getAllGridColumns().forEach(item => {
            //     result[item.colId] = null;
            // });
            return this.calculatePinnedBottomData(result);
        },
        calculatePinnedBottomData(target) {
            //**list of columns fo aggregation**
            let columnsWithAggregation = ['spent', 'budgeted', 'difference'];
            columnsWithAggregation.forEach(element => {
                target[element] = 0;
                this.gridApi.forEachNodeAfterFilter((rowNode) => {
                    if (rowNode.data[element])
                        target[element] += Number(Number(rowNode.data[element]).toFixed(2));
                });
            })
            return target;
        },
        calculateHeightAndTotal() {
            this.height = (rowHeight * (this.griddata.length + 2) + 10).toString() + "px";
            //let pinnedBottomData = this.generatePinnedBottomData();
            //this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
        },
        sortCategories(categories) {
            return categories.sort((a, b) => {
                // other should always be at the bottom
                if (a.name == 'other') {
                    return 1;
                }
                if (b.name == 'other') {
                    return -1;
                }

                // otherwise sort by budget size
                if (a.budgeted > b.budgeted) {
                    return -1;
                }
                if (a.budgeted < b.budgeted) {
                    return 1;
                }
                return 0;
            });
        }
    },
    watch: {
        data(newData) {
            this.griddata = this.sortCategories(newData);
            //this.gridApi && this.gridApi.setRowData(newData);
            this.calculateHeightAndTotal()
        },
        loading(newValue) {
            
            if (newValue) {
                this.gridApi && this.gridApi.showLoadingOverlay();
            }
            else {
                this.gridApi && this.gridApi.hideOverlay();
            }
        },
    },
    computed: {
        // Add your computed properties here
    },
    updated() {
        if (this.loading) {
            this.gridApi && this.gridApi.showLoadingOverlay();
        }
        else {
            this.gridApi && this.gridApi && this.gridApi.hideOverlay();
        }
        this.calculateHeightAndTotal()
        
    },
    created() {
        this.overlayLoadingTemplate =
            '<span class="ag-overlay-loading-center">Getting transactions...</span>';
        
    }

}
</script>

<style scoped>
    .category-grid {
        /* Add your component styles here */
    }
    .budget-item-grid {
        margin-bottom: 40px;
        margin-top: 10px;   
    }
    .budget-item-grid-title {
        margin-left: 50px;
    }
    .green {
        color: green;
    }
    .red {
        color: red;
    }
</style>