//export const API_TOKEN = '16b669513f0eae3b6947dbe265a1d59c5e841d6c35f9f1c46cdb84c6eee3878e'
export const API_ROOT = 'https://g9m484ky6h.execute-api.us-east-1.amazonaws.com/production'
export const API_TOKEN = 'EXTXfNCkjW3YrG3XNySgk4fAhllW1pzb25sGUGQ5'
export const ENV = 'development'

const DEVELOPMENT_URL_ROOT = 'https://g9m484ky6h.execute-api.us-east-1.amazonaws.com/production'
const PRODUCTION_URL_ROOT = 'https://g9m484ky6h.execute-api.us-east-1.amazonaws.com/production'

export function get_url_root() {
    if (ENV === 'development') {
        return DEVELOPMENT_URL_ROOT
    } else {
        return PRODUCTION_URL_ROOT
    }
}
